@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: DMSerifDisplay-Italic;
  src: url("fonts/DMSerifDisplay-Italic.ttf") format("opentype");
}
@font-face {
  font-family: DMSerifDisplay;
  src: url("fonts/DMSerifDisplay-Regular.ttf") format("opentype");
}
body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-container svg {
  font-size: 75px;
  border-radius: 10px;
}

.icon:hover {
  color: #00ccff;
}
